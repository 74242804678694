import React, { FC, MouseEventHandler } from 'react';
import Link from 'components/WebshopLink';
import { highlightSearch } from 'modules/header/components/Suggest/Suggest.utils';
import suggestStyles from 'modules/header/components/Suggest/Suggest.scss';

interface ItemProp {
  fhId: string;
  id: string;
  url?: string;
  label: string;
}

const getSuggestedCategoryUrl = (suggestedCategory: ItemProp) =>
  suggestedCategory.url
    ? new URL(suggestedCategory.url).pathname
    : suggestedCategory.label?.toLowerCase().replace(/[^a-z0-9]+/g, '-');

interface CategoryItemsProps {
  /** Handle click callback */
  handleClick?: MouseEventHandler<HTMLAnchorElement>;
  /** Items to display */
  items?: ItemProp[];
  /** Search term to highlight  */
  searchTerm?: string;
}

const CategoryItems: FC<CategoryItemsProps> = ({ handleClick, items, searchTerm }) =>
  items
    ? items.map((item) => (
        <li key={item.id} className={suggestStyles.item}>
          <Link
            data-purpose="header.suggest.category.link"
            href={getSuggestedCategoryUrl(item)}
            onClick={handleClick}
            className={suggestStyles.link}
          >
            <span>{highlightSearch(item.label, searchTerm)}</span>
          </Link>
        </li>
      ))
    : null;

export default CategoryItems;
