import React, { type FC, memo, MouseEvent, useCallback } from 'react';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import useLanguage from 'hooks/useLanguage';
import Link from 'components/WebshopLink';

import styles from 'modules/header/components/MainNavigation/components/MainNavigationLink/MainNavigationLink.scss';

const MOEMAX = 'moemax';
const XXXLUTZ = 'xxxlutz';
const MOEBELIX = 'moebelix';
const SALE_LINK = '% Sale %';
const MBX_SALE_LINK = 'SALE %';
const ANGEBOTE_LINK = ['Angebote', 'Kampanj'];

interface MainNavigationLinkProps {
  className?: string;
  hasSubItems?: boolean;
  link: string;
  name: string;
  target: string;
}

const MainNavigationLink: FC<MainNavigationLinkProps> = (props) => {
  const { className, hasSubItems, link, name, target } = props;

  const { pathname } = useLocation();
  const ariaCurrentPage = pathname === link ? 'page' : null;

  const tracking = useTracking(props, 'MainNavigationLink');

  const language = useLanguage();
  const isNameToHighlight =
    (language.rail === MOEMAX && name === SALE_LINK) ||
    (language.rail === XXXLUTZ && ANGEBOTE_LINK.includes(name)) ||
    (language.rail === MOEBELIX && name === MBX_SALE_LINK);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      tracking(event);
    },
    [tracking],
  );

  return (
    <Link
      ariaCurrent={ariaCurrentPage}
      ariaHasPopup={hasSubItems}
      className={classnames(styles.link, className, {
        [styles.highlight]: isNameToHighlight,
      })}
      data-purpose="mainNavigation.link"
      href={link}
      onClick={handleClick}
      target={target}
    >
      {name}
    </Link>
  );
};

export default memo(track(tagComponent('MainNavigationLink'))(MainNavigationLink));
