import { gql } from '@apollo/client';

const fredhopperData = `
  formattedId
  id
  image
  label
  price
  type
  url
  fhId`;

export const FHSUGGEST_QUERY = gql`
  query fredhopperSuggest($term: String!) {
    getFredhopperSuggest(term: $term) {
      categories {
        ${fredhopperData}
      }
      keywords {
        ${fredhopperData}
      }
      products {
        ${fredhopperData}
      }
    }
  }
`;
