import { type MouseEvent, createContext, useContext } from 'react';
import { noop } from '@xxxlgroup/hydra-utils/common';
import type {
  NavigationItemType,
  NavigationLevelStack,
} from 'modules/header/components/SidebarMenu/SidebarMenu.types';

interface SidebarMenuContextInterface {
  activateNavigationItem: (
    event: MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement>,
    newLevel: number,
    itemData: NavigationItemType,
  ) => void;
  activeLevelStack: NavigationLevelStack[];
}

export const SidebarMenuContext = createContext<SidebarMenuContextInterface>({
  activateNavigationItem: noop,
  activeLevelStack: [],
});

export const useSidebarMenuContext = () => useContext(SidebarMenuContext);
