import React, {
  FC,
  MouseEvent,
  MouseEventHandler,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import classnames from 'classnames';
import track from 'react-tracking';
import { useQuery } from '@apollo/client';
import { SearchMethod } from 'pages/CategoryView/provider/Category.provider';
import useSearchResultsTracking from 'components/Listing/hooks/useSearchResultsTracking';
import { FHSUGGEST_QUERY } from 'modules/header/components/Suggest/SuggestFredhopper.query';
import { SUGGESTSEARCH_QUERY } from 'modules/header/components/Suggest/SuggestSearch.query';
import { debouncing } from 'utils/function';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useMessage from 'components/Message/useMessage';
import { isObjectEmpty } from '@xxxlgroup/hydra-utils/common';
import { useSearchBar } from 'modules/header/components/SearchBar/SearchBar.variables';
import SuggestList from 'modules/header/components/Suggest/components/SuggestList';
import KeywordItems from 'modules/header/components/Suggest/components/KeywordItems';
import CategoryItems from 'modules/header/components/Suggest/components/CategoryItems';
import ProductItems from 'modules/header/components/Suggest/components/ProductItems';

import styles from 'modules/header/components/Suggest/Suggest.scss';
import { SEARCHTERM_LENGTH } from 'modules/header/components/SearchBar/useSearchBarController';

const MAXIMUM_ITEMS_PER_GROUP = 3;
const SUGGEST_SEARCH_METHOD_MAP: Record<string, SearchMethod> = {
  'header.suggest.keyword.link': 'suggested_keyword',
  'header.suggest.category.link': 'suggested_category',
  'header.suggest.product.link': 'suggested_product',
};

const getReducedData = (array: any) => array.slice(0, MAXIMUM_ITEMS_PER_GROUP);

interface SuggestProps {
  /** Additional class name */
  className?: string;
  /** Input field reference */
  inputWrapperRef: React.RefObject<HTMLInputElement>;
  /** callback for clicking on the link */
  onClickLink: () => void;
  /** callback that sets if the suggest dropdown is empty or not */
  setIsSuggestEmpty: (isEmpty: boolean) => void;
  /** debounce term state from searchBar */
  term: string;
  /** update debounce term function from searchBar */
  updateDebounceTerm: (term: string) => void;
}

const Suggest: FC<SuggestProps> = (props) => {
  const { className, inputWrapperRef, onClickLink, setIsSuggestEmpty, term, updateDebounceTerm } =
    props;
  const tracking = useTracking(props, 'Suggest');
  const [isVoiceSearchShown, newSuggestSearchEnabled] = useFeatureFlags([
    'poseidon.searchBar.voiceSearch.enabled',
    'poseidon.searchBar.suggestSearch.new.enabled',
  ]);
  const [keywordsTitle, categoriesTitle, productsTitle] = useMessage([
    'wxs.search.results.keywordTitle',
    'wxs.search.results.categoriesTitle',
    'wxs.search.results.productsTitle',
  ]);
  const { searchResultTracking, searchTracking } = useSearchResultsTracking(tracking);

  const query = newSuggestSearchEnabled ? SUGGESTSEARCH_QUERY : FHSUGGEST_QUERY;

  const { data, previousData, variables, error } = useQuery(query, {
    variables: {
      term,
    },
  });

  const {
    categories = [],
    keywords = [],
    products = [],
  } = newSuggestSearchEnabled
    ? data?.getSuggestSearch ?? previousData?.getSuggestSearch ?? {}
    : data?.getFredhopperSuggest ?? previousData?.getFredhopperSuggest ?? {};

  const { updateSuggestVisibility, updateSearchTerm } = useSearchBar();

  const isSuggestEmpty =
    isObjectEmpty(products) && isObjectEmpty(categories) && isObjectEmpty(keywords);

  useEffect(() => {
    setIsSuggestEmpty(isSuggestEmpty);
  }, [isSuggestEmpty, setIsSuggestEmpty]);

  const checkedSearchTerm = variables?.term || '';
  const searchTerm = decodeURIComponent(checkedSearchTerm);

  const handleClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (event) => {
      const link = event.currentTarget.closest('a');
      searchTracking.current.method =
        SUGGEST_SEARCH_METHOD_MAP[String(link?.getAttribute('data-purpose'))];

      if (searchTracking.current.method === 'suggested_product') {
        searchTracking.current.term = link?.getAttribute('data-product-id') ?? '';
        searchTracking.current.productCodes = [searchTracking.current.term];
        searchTracking.current.totalResults = 1;

        searchResultTracking();
      } else {
        searchTracking.current.term = String(link?.textContent);
      }
      updateSuggestVisibility(false);
      onClickLink();
    },
    [onClickLink, searchTracking, searchResultTracking, updateSuggestVisibility],
  );

  const typeAhead = useCallback(
    (suggestedTerm: string) => (event: MouseEvent<HTMLButtonElement>) => {
      tracking(event);

      if (inputWrapperRef.current?.firstChild) {
        (inputWrapperRef.current.firstChild as HTMLInputElement).focus();
      }

      const typeAheadTerm = `${suggestedTerm} `;
      updateSearchTerm(typeAheadTerm);
      updateDebounceTerm(typeAheadTerm);
    },
    [tracking, inputWrapperRef, updateSearchTerm, updateDebounceTerm],
  );

  const debouncedLeave = useMemo(
    () =>
      debouncing(() => {
        updateSuggestVisibility(false);
        if (inputWrapperRef.current?.firstChild) {
          (inputWrapperRef.current.firstChild as HTMLInputElement).blur();
        }
      }),
    [updateSuggestVisibility, inputWrapperRef],
  );

  const handleHover = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>) => {
      tracking(event);
    },
    [tracking],
  );

  const handleMouseEnter = useCallback(() => {
    debouncedLeave.cancel();
  }, [debouncedLeave]);

  if (isSuggestEmpty || checkedSearchTerm.length < SEARCHTERM_LENGTH || error) {
    return null;
  }

  return (
    <div
      className={classnames(className, styles.suggest, {
        [styles.suggestWithVoiceSearch]: isVoiceSearchShown,
      })}
      data-purpose="header.suggest.container"
      onMouseEnter={handleMouseEnter}
    >
      <SuggestList heading={keywordsTitle} data-purpose="header.suggest.keywords">
        {keywords && (
          <KeywordItems
            handleClick={handleClick}
            handleHover={handleHover}
            items={getReducedData(keywords)}
            searchTerm={searchTerm}
            typeAhead={typeAhead}
          />
        )}
      </SuggestList>
      <SuggestList heading={categoriesTitle} data-purpose="header.suggest.categories">
        {categories && (
          <CategoryItems
            handleClick={handleClick}
            items={getReducedData(categories)}
            searchTerm={searchTerm}
          />
        )}
      </SuggestList>
      <SuggestList heading={productsTitle} data-purpose="header.suggest.products">
        {products && (
          <ProductItems
            handleClick={handleClick}
            items={getReducedData(products)}
            searchTerm={searchTerm}
          />
        )}
      </SuggestList>
    </div>
  );
};

export default track(tagComponent('Suggest'))(Suggest);
