import { type MouseEvent, type MouseEventHandler, useCallback, useState } from 'react';
import {
  NavigationDataType,
  NavigationItemType,
  NavigationLevelStack,
} from 'modules/header/components/SidebarMenu/SidebarMenu.types';
import { useTracking } from 'utils/tracking/hooks';

const useSidebarMenu = (
  onClose: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement>,
) => {
  const [displayedLevel, setDisplayedLevel] = useState(0);
  const [activeLevelStack, setActiveLevelStack] = useState<NavigationLevelStack[]>([]);

  const tracking = useTracking({}, 'SidebarNavigationController');

  const buildNewActiveLevelStack = useCallback(
    (newLevel: number, itemData: NavigationItemType, target: HTMLElement) => {
      if (displayedLevel >= newLevel) {
        activeLevelStack.pop();
      }

      if (displayedLevel <= newLevel) {
        return [...activeLevelStack, { itemData, target }];
      }

      return [...activeLevelStack];
    },
    [activeLevelStack, displayedLevel],
  );

  const focusNextElement = useCallback((targetElement: HTMLAnchorElement) => {
    const navElement = targetElement.closest('nav');

    const transitionEndHandler = () => {
      targetElement.nextElementSibling
        ?.querySelector<HTMLAnchorElement>('[data-purpose="sidebarMenu.showAll.link"]')
        ?.focus();
      navElement?.removeEventListener('transitionend', transitionEndHandler);
    };

    // Let's wait until the CSS transition is finished until we focus on the next element
    navElement?.addEventListener('transitionend', transitionEndHandler);
  }, []);

  const focusPreviousElement = useCallback((elementToFocus: HTMLButtonElement) => {
    const navElement = elementToFocus.closest('nav');

    const transitionEndHandler = () => {
      elementToFocus.focus();
      navElement?.removeEventListener('transitionend', transitionEndHandler);
    };

    // Let's wait until the CSS transition is finished until we focus on the previous element
    navElement?.addEventListener('transitionend', transitionEndHandler);
  }, []);

  const getTrackingDataFromActiveLevelStack = useCallback(
    (newLevel: number) => {
      const reducedNames = activeLevelStack.reduce(
        (previousValue, currentValue) =>
          `${previousValue}|${(currentValue.itemData as NavigationDataType).name}`,
        'Main Navigation',
      );

      return {
        currentLevel: displayedLevel,
        newLevel,
        parents: reducedNames,
      };
    },
    [activeLevelStack, displayedLevel],
  );

  const activateNavigationItem = useCallback(
    (
      event: MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement>,
      newLevel: number,
      itemData: NavigationItemType,
    ) => {
      const target = event.target as HTMLElement;
      let trackingType = null;

      if (target.tagName === 'A') {
        focusNextElement(target as HTMLAnchorElement);

        trackingType =
          itemData.type === 'cmslinkcomponent' ? 'NavigationItemLink' : 'NavigationCard';
      } else if (target.tagName === 'BUTTON') {
        const newFocusElement = activeLevelStack[activeLevelStack.length - 1]
          .target as HTMLButtonElement;
        focusPreviousElement(newFocusElement);

        trackingType = 'BackButton';
      }

      const trackingData = getTrackingDataFromActiveLevelStack(newLevel);
      tracking(event, {
        type: 'SidebarNavigationController',
        context: [],
        props: { clickedComponent: trackingType, ...trackingData, ...itemData },
      });

      if (!itemData.hasSubItems) {
        onClose(event);
        return;
      }

      event.preventDefault();

      setDisplayedLevel(newLevel);
      setActiveLevelStack(buildNewActiveLevelStack(newLevel, itemData, target));
    },
    [
      activeLevelStack,
      buildNewActiveLevelStack,
      focusNextElement,
      focusPreviousElement,
      getTrackingDataFromActiveLevelStack,
      onClose,
      tracking,
    ],
  );

  return {
    activateNavigationItem,
    activeLevelStack,
    displayedLevel,
  };
};

export default useSidebarMenu;
