import React, { FC } from 'react';
import { Heading } from '@xxxlgroup/hydra-ui-components';

import styles from 'modules/header/components/Suggest/components/SuggestList/SuggestList.scss';

interface SuggestListProps {
  /** Children items */
  children?: React.ReactNode;
  /** Data-purpose attribute of section  */
  'data-purpose': string;
  /** Heading text to display before items */
  heading: string;
}

const SuggestList: FC<SuggestListProps> = ({ heading, 'data-purpose': dataPurpose, children }) =>
  children && (
    <div className={styles.area} data-purpose={dataPurpose}>
      <Heading level={3} data-purpose="header.suggest.headline" content={heading} />
      <ul className={styles.links}>{children}</ul>
    </div>
  );

export default SuggestList;
