import React, {
  type ComponentType,
  type FC,
  type MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import { useImpressionTracker } from 'utils/tracking/hooks';
import { config } from '@xxxlgroup/hydra-config';
import { Card, CardOverlay, CardImage } from '@xxxlgroup/hydra-ui-components';
import withLinkModifier from '@xxxlgroup/hydra-ui-components/dist/common/molecules/Card/hoc/withLinkModifier';
import Link from 'components/WebshopLink';
import { traverseAncestors } from 'modules/header/components/SidebarMenu/components/NavigationCard/traverseAncestors';
import type { NavigationCardDataType } from 'modules/header/components/SidebarMenu/SidebarMenu.types';
import { useSidebarMenuContext } from 'modules/header/components/SidebarMenu/SidebarMenu.context';

import styles from 'modules/header/components/SidebarMenu/components/NavigationCard/NavigationCard.scss';

const TRACKING_PROPS = {
  event: 'impression',
  type: 'NavigationCard',
  purpose: 'navigation.teaser.flyout',
};

interface NavigationCardProps {
  /** includes all necessary card data */
  cardData: NavigationCardDataType;
  level: number;
}

const NavigationCard: FC<NavigationCardProps> = (props) => {
  const { cardData, level } = props;
  const { cmsComponentName, link, image, target, title, variant, id } = cardData;

  const cardRef = useRef<HTMLLIElement>(null);
  const [parentCategories, setParentCategories] = useState<string[]>([]);

  useEffect(() => {
    const htmlNode = cardRef.current;
    const parentCategories: string[] = [];
    htmlNode &&
      traverseAncestors(htmlNode, (ancestor: HTMLElement) => {
        parentCategories.unshift(ancestor.innerText);
      });

    setParentCategories(parentCategories);
  }, []);

  const { setRef } = useImpressionTracker(
    {
      name: cmsComponentName,
      ...props,
      ...cardData,
      creative: 'cmsTextImageComponent',
      id,
      position: parentCategories.join('::'),
    },
    'useImpressionTracker(NavigationCard)',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    TRACKING_PROPS,
  );

  const { activateNavigationItem } = useSidebarMenuContext();

  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      activateNavigationItem(event, level, cardData);
    },
    [activateNavigationItem, cardData, level],
  );

  const renderOverlay = () =>
    title ? (
      <CardOverlay key="overlay" variant={variant} hasLink={!!link}>
        {title}
      </CardOverlay>
    ) : null;

  const imageSizes = [
    `(max-width: ${config.breakpoints.md}px) calc(100vw - 3rem)`,
    `(max-width: ${config.breakpoints.lg}px) calc(100vw - 1.5rem)`,
    `(max-width: 1600px) calc(100vw - 17rem - 1.5rem)`,
    `(max-width: ${config.breakpoints.xxl}px) 640px`,
    `584px`,
  ];

  return (
    <li ref={cardRef}>
      <div ref={setRef}>
        <Card
          variant="image"
          target={target}
          data-purpose="teaserCard.card"
          className={styles.customTeaserCardDimensions}
          overlay={renderOverlay()}
          onClick={handleClick}
          renderLink={
            link &&
            withLinkModifier(Link as ComponentType)({
              ...TRACKING_PROPS,
              'data-purpose': 'sidebarMenu.teaser.flyout',
              href: link,
              isPromotion: cardData?.isPromotion,
            })
          }
        >
          {image && <CardImage isBackground source={image} imageSizes={imageSizes.join(',')} />}
        </Card>
      </div>
    </li>
  );
};

export default track(tagComponent('NavigationCard'))(NavigationCard);
