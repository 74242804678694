import React, { type FC, type MouseEvent, useCallback, useMemo } from 'react';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import { arrowRight } from '@xxxlgroup/hydra-icons';
import useMessage from 'components/Message/useMessage';
import Link from 'components/WebshopLink';
import type { NavigationDataType } from 'modules/header/components/SidebarMenu/SidebarMenu.types';
import { useSidebarMenuContext } from 'modules/header/components/SidebarMenu/SidebarMenu.context';

import styles from 'modules/header/components/SidebarMenu/components/ShowCurrentMenu/ShowCurrentMenu.scss';
import { Icon } from '@xxxlgroup/hydra-ui-components';

interface ShowCurrentMenuProps {
  level: number;
}

const ShowCurrentMenu: FC<ShowCurrentMenuProps> = ({ level }) => {
  const { activateNavigationItem, activeLevelStack } = useSidebarMenuContext();

  const [showAllMessage] = useMessage(['sidebarNavigation.showAll.title'], null, true);

  const currentLevel = level - 1;
  const itemData = useMemo(
    () => (activeLevelStack[currentLevel]?.itemData || {}) as NavigationDataType,
    [activeLevelStack, currentLevel],
  );

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      activateNavigationItem(event, currentLevel, {
        ...itemData,
        hasSubItems: false,
        subItems: [],
        subItemsCount: 0,
      });
    },
    [activateNavigationItem, currentLevel, itemData],
  );

  if (level === 0 || activeLevelStack.length === 0 || !itemData.link) {
    return null;
  }

  const inertLink = level < activeLevelStack.length;

  const target = itemData?.link?.startsWith('http') ? '_blank' : '_self';

  return (
    <li>
      <Link
        aria-current="page"
        className={styles.link}
        data-purpose="sidebarMenu.showAll.link"
        href={itemData.link}
        inert={inertLink}
        onClick={handleClick}
        target={target}
      >
        <span className={styles.title}>{itemData.name}</span>
        <span className={styles.messageLink}>
          {showAllMessage}
          <Icon aria-hidden className={styles.icon} glyph={arrowRight} />
        </span>
      </Link>
    </li>
  );
};

export default track(tagComponent('ShowCurrentMenu'))(ShowCurrentMenu);
