import { gql } from '@apollo/client';

const suggestData = `
  formattedId
  id
  image
  label
  price
  type
  url
  fhId`;

export const SUGGESTSEARCH_QUERY = gql`
  query suggestSearch($term: String!) {
    getSuggestSearch(term: $term) {
      categories {
        ${suggestData}
      }
      keywords {
        ${suggestData}
      }
      products {
        ${suggestData}
      }
    }
  }
`;
