const waitForElement = (selector: string, timeout = 500, rootNode: HTMLElement = document.body) => {
  const timeoutPromise = new Promise((resolve: (value: null) => void) =>
    setTimeout(() => {
      resolve(null);
    }, timeout),
  );

  const elementPromise = new Promise((resolve: (value: HTMLElement | null) => void) => {
    if (rootNode.querySelector(selector)) {
      resolve(rootNode.querySelector(selector));
      return;
    }

    const observer = new MutationObserver(() => {
      if (rootNode.querySelector(selector)) {
        observer.disconnect();
        resolve(rootNode.querySelector(selector));
      }
    });

    observer.observe(rootNode, {
      childList: true,
      subtree: true,
    });
  });

  return Promise.race([timeoutPromise, elementPromise]);
};

export default waitForElement;
