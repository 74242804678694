export const traverseAncestors = (
  element: HTMLElement,
  callback: (ancestor: HTMLElement) => void,
) => {
  let ancestor: HTMLElement | null = element.parentElement;

  while (ancestor && ancestor.tagName !== 'NAV') {
    const firstChild = ancestor.firstElementChild as HTMLElement | null;
    if (firstChild?.tagName === 'A') {
      callback(firstChild);
    }
    ancestor = ancestor.parentElement;
  }
};
