import React, { type FC } from 'react';
import classnames from 'classnames';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import NavigationList from 'modules/header/components/SidebarMenu/components/NavigationList';
import NavigationItemLink from 'modules/header/components/SidebarMenu/components/NavigationItemLink';
import type { NavigationDataType } from 'modules/header/components/SidebarMenu/SidebarMenu.types';
import { useSidebarMenuContext } from 'modules/header/components/SidebarMenu/SidebarMenu.context';

import styles from 'modules/header/components/SidebarMenu/components/NavigationItem/NavigationItem.scss';

interface NavigationItemProps {
  /** data of the current navigation item */
  itemData: NavigationDataType;
  /** how deeply the component is nested within the navigation */
  level: number;
}

const NavigationItem: FC<NavigationItemProps> = (props) => {
  const { itemData, level } = props;
  const { hasSubItems } = itemData;

  const { activeLevelStack } = useSidebarMenuContext();

  const isActive =
    activeLevelStack[level] && activeLevelStack[level].itemData.code === itemData.code;
  const shouldBeVisible = isActive || level === activeLevelStack.length;
  const shouldRenderSubItems = hasSubItems && shouldBeVisible;

  const inertLI = !shouldBeVisible && level < activeLevelStack.length ? '' : undefined;
  const inertLink = inertLI !== '' && level < activeLevelStack.length;
  const nextLevel = level + 1;

  return (
    <li
      data-level={level}
      className={classnames(styles.listItem, styles[`level${level}`])}
      inert={inertLI}
    >
      <NavigationItemLink inert={inertLink} itemData={itemData} level={level} />
      {shouldRenderSubItems && (
        <NavigationList isActive={isActive} level={nextLevel} parentItem={itemData} />
      )}
    </li>
  );
};

export default track(tagComponent('NavigationItem'))(NavigationItem);
