import React, { type FC, type MouseEvent, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon/pseudoIcon';
import { bars } from '@xxxlgroup/hydra-icons';
import useMessage from 'components/Message/useMessage';
import SidebarMenu from 'modules/header/components/SidebarMenu';

import styles from 'modules/header/components/Hamburger/Hamburger.scss';

interface HamburgerProps {}

const Hamburger: FC<HamburgerProps> = () => {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [initialPath, setInitialPath] = useState<string | null>(null);

  const [iconStyle, iconClassName] = pseudoIcon(bars);

  const tracking = useTracking({}, 'Hamburger');

  const [hamburgerButtonTitle, hamburgerButtonAriaLabel] = useMessage(
    ['header.hamburger.label', 'wxs.header.hamburger.button'],
    null,
    true,
  );

  // We decided to solve the connection between MainNavigation and SidebarMenu by facilitating an ordinary
  // event handler instead of introducing yet another context provider which re-renders everything unnecessarily
  const handleMainNavigationClick = useCallback((event: Event) => {
    const target = event.target as HTMLAnchorElement;

    if (target.tagName === 'A' && target.getAttribute('aria-haspopup') === 'true') {
      event.preventDefault();
      setInitialPath(target.getAttribute('href'));
      setIsMenuActive(true);
    }
  }, []);

  useEffect(() => {
    const mainNavigation = document.querySelector('[data-purpose="mainNavigation.container"]');

    mainNavigation?.addEventListener('click', handleMainNavigationClick);

    return () => {
      mainNavigation?.removeEventListener('click', handleMainNavigationClick);
    };
  }, [handleMainNavigationClick]);

  const hamburgerClick = useCallback(
    (event: React.PointerEvent<HTMLButtonElement>) => {
      tracking(event, {
        context: [],
        type: 'Hamburger',
      });
      setInitialPath(null);
      setIsMenuActive(() => !isMenuActive);
    },
    [isMenuActive, tracking],
  );

  const handleOnClose = useCallback(
    (event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
      let closedBy = 'Link';

      if ((event.target as HTMLElement).nodeName === 'DIV') {
        closedBy = 'Backdrop';
      } else if ((event.target as HTMLElement).nodeName === 'BUTTON') {
        closedBy = 'CloseButton';
      }

      tracking(event, {
        context: [],
        type: 'Hamburger',
        closedBy,
      });
      setIsMenuActive(false);
    },
    [tracking],
  );

  return (
    <>
      <button
        aria-label={hamburgerButtonAriaLabel}
        className={classnames(styles.hamburger, iconClassName)}
        data-purpose="header.button.hamburger"
        data-toggle="collapse"
        onClick={hamburgerClick}
        style={iconStyle}
        type="button"
      >
        <span className={styles.menuTitle}>{hamburgerButtonTitle}</span>
      </button>
      {isMenuActive && <SidebarMenu initialPath={initialPath} onClose={handleOnClose} />}
    </>
  );
};

export default track(tagComponent('Hamburger'))(Hamburger);
