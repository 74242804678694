import React, { type FC } from 'react';
import { useQuery } from '@apollo/client';
import classnames from 'classnames';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import { MAIN_NAVIGATION_QUERY } from 'modules/header/components/MainNavigation/MainNavigation.query';
import ErrorHandler from 'components/ErrorHandler';
import MainNavigationLink from 'modules/header/components/MainNavigation/components/MainNavigationLink';

import styles from 'modules/header/components/MainNavigation/MainNavigation.scss';

interface MainNavigationProps {
  className?: string;
}

const MainNavigation: FC<MainNavigationProps> = (props) => {
  const { data: navigationData, error } = useQuery(MAIN_NAVIGATION_QUERY, {
    variables: {
      codes: ['header_nav_root'],
      levelAmount: 1,
      levelOffset: 0,
    },
    fetchPolicy: 'cache-and-network',
  });

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return (
    <nav
      className={classnames(styles.navigation, props.className)}
      data-purpose="mainNavigation.container"
    >
      <ul className={styles.list}>
        {navigationData?.navigation.data.main.map((item: any) => (
          <li key={item.code} className={styles.listItem}>
            <MainNavigationLink
              hasSubItems={item.hasSubItems}
              link={item.link}
              name={item.name}
              target={item.target}
            />
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default track(tagComponent('MainNavigation'))(MainNavigation);
