import React, {
  type FC,
  type MouseEvent,
  type MouseEventHandler,
  useEffect,
  useMemo,
  useState,
} from 'react';
import classnames from 'classnames';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import { useAuth } from 'modules/customer-authentication/AuthContext';
import { Modal } from '@xxxlgroup/hydra-ui-components';
import waitForElement from 'modules/header/components/SidebarMenu/utils/waitForElement';
import BackButton from 'modules/header/components/SidebarMenu/components/BackButton';
import ServiceLinks from 'modules/header/components/SidebarMenu/components/ServiceLinks';
import MetaNavigation from 'modules/header/components/SidebarMenu/components/MetaNavigation';
import ContrastToggle from 'components/ContrastToggle/ContrastToggle';
import LoginLogoutButton from 'modules/header/components/SidebarMenu/components/LoginLogoutButton';
import NavigationList from 'modules/header/components/SidebarMenu/components/NavigationList';
import { SidebarMenuContext } from 'modules/header/components/SidebarMenu/SidebarMenu.context';
import useSidebarMenu from 'modules/header/components/SidebarMenu/hooks/useSidebarMenu';

import styles from 'modules/header/components/SidebarMenu/SidebarMenu.scss';

interface SidebarMenuProps {
  initialPath?: string | null;
  onClose: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement>;
}

const SidebarMenu: FC<SidebarMenuProps> = ({ initialPath, onClose }) => {
  const { activateNavigationItem, activeLevelStack, displayedLevel } = useSidebarMenu(onClose);
  const [useSlidingAnimation, setUseSlidingAnimation] = useState(!initialPath);
  const { isAnonymous } = useAuth();

  useEffect(() => {
    if (initialPath) {
      waitForElement(
        `[data-purpose="sidebarMenu.navigation.container"] > ul > li > a[href="${initialPath}"]`,
      ).then((initialLink: HTMLElement | null) => {
        initialLink?.click();

        waitForElement('[data-purpose="sidebarMenu.showAll.link"]').then(
          (overviewLink: HTMLElement | null) => {
            overviewLink?.focus();
          },
        );

        const modalElement = initialLink?.closest('[data-purpose="modal.sidebar-left.wrapper"]');

        const animationEndHandler = () => {
          setUseSlidingAnimation(true);
          modalElement?.removeEventListener('animationend', animationEndHandler);
        };

        // Let's wait until the CSS animation is finished until we activate the transition again
        modalElement?.addEventListener('animationend', animationEndHandler);
      });
    }
  }, [initialPath]);

  const contextValues = useMemo(
    () => ({
      activateNavigationItem,
      activeLevelStack,
    }),
    [activateNavigationItem, activeLevelStack],
  );

  const inertElements = activeLevelStack.length > 0 ? '' : undefined;

  return (
    <SidebarMenuContext.Provider value={contextValues}>
      <Modal
        className={{
          modal: classnames(styles.modal, styles.highSpecificity),
          close: styles.sidebarClose,
          head: styles.sidebarHead,
          heading: styles.sidebarHeading,
          body: styles.sidebarBody,
        }}
        closeButtonHasInitialFocus={!initialPath}
        hasBackdropOnMobile
        heading={<BackButton />}
        onClose={onClose as (event: MouseEvent | KeyboardEvent) => void}
        stickyHeader={false}
        variant="sidebar-left"
      >
        <nav
          data-purpose="sidebarMenu.navigation.container"
          className={classnames(styles.navigationPosition, styles[`level${displayedLevel}`], {
            [styles.scroll]: activeLevelStack.length === 0,
            [styles.noTransition]: !useSlidingAnimation,
          })}
          tabIndex={-1}
        >
          <NavigationList />
          <div className={styles.section} inert={inertElements}>
            <ServiceLinks onClick={onClose} />
          </div>
          <div className={styles.section} inert={inertElements}>
            <MetaNavigation onClick={onClose} />
          </div>
          <div className={styles.section} inert={inertElements}>
            <ContrastToggle className={styles.contrastToggle} />
          </div>
          <div
            className={classnames(styles.section, { [styles.sticky]: isAnonymous })}
            inert={inertElements}
          >
            <LoginLogoutButton onClick={onClose} />
          </div>
        </nav>
      </Modal>
    </SidebarMenuContext.Provider>
  );
};

export default track(tagComponent('SidebarMenu'))(SidebarMenu);
