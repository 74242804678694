import { gql } from '@apollo/client';

export const SEARCHTERM_QUERY = gql`
  query searchterm {
    searchBar @client {
      term
      suggest
    }
  }
`;
