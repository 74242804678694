import React, { type FC, type MouseEvent, type MouseEventHandler, useCallback, memo } from 'react';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import { useGetMetaNavigationQuery } from 'graphql-types/generated/types-generated';
import { Skeleton } from '@xxxlgroup/hydra-ui-components';
import Link from 'components/WebshopLink';

import styles from 'modules/header/components/SidebarMenu/components/MetaNavigation/MetaNavigation.scss';

interface SidebarMetaNavigationProps {
  onClick: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
}

const MetaNavigation: FC<SidebarMetaNavigationProps> = ({ onClick }) => {
  const { data, loading } = useGetMetaNavigationQuery();
  const tracking = useTracking({}, 'MetaNavigation');

  const handleClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
      tracking(event);
      onClick(event);
    },
    [onClick, tracking],
  );

  if (loading) {
    return <Skeleton count={3} />;
  }

  if (!data) {
    return null;
  }

  const serviceNodes =
    data.getMetaNavigation?.sidebarMenuNodes && data.getMetaNavigation?.sidebarMenuNodes.length > 0
      ? data.getMetaNavigation?.sidebarMenuNodes
      : data.getMetaNavigation?.serviceNodes;

  return (
    <>
      {serviceNodes?.map((node) => (
        <Link
          data-purpose="sidebarMenu.metaNavigation.service.link"
          href={node?.link?.url}
          key={node?.link?.url}
          className={styles.link}
          onClick={handleClick}
          target={node?.link?.target || '_self'}
        >
          {node?.link?.linkName}
        </Link>
      ))}
    </>
  );
};

export default track(tagComponent('MetaNavigation'))(memo(MetaNavigation));
