import React, { type FC, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import { useQuery } from '@apollo/client';
import ErrorHandler from 'components/ErrorHandler';
import type {
  NavigationDataType,
  NavigationItemType,
} from 'modules/header/components/SidebarMenu/SidebarMenu.types';
import { NAVIGATION_QUERY } from 'modules/header/components/SidebarMenu/utils/navigation.query';
import NavigationItem from 'modules/header/components/SidebarMenu/components/NavigationItem';
import NavigationCard from 'modules/header/components/SidebarMenu/components/NavigationCard';
import ShowCurrentMenu from 'modules/header/components/SidebarMenu/components/ShowCurrentMenu';

import styles from 'modules/header/components/SidebarMenu/components/NavigationList/NavigationList.scss';

interface NavigationListProps {
  /** Whether this list of subItems should be shown */
  isActive?: boolean;
  /** how deeply the component is nested within the navigation */
  level?: number;
  /** parent category for this list */
  parentItem?: NavigationDataType;
}

const NavigationList: FC<NavigationListProps> = (props) => {
  const { isActive = false, level = 0, parentItem } = props;

  const { data: navigationData, error } = useQuery(NAVIGATION_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      codes: [parentItem?.code || 'header_nav_root'],
      levelAmount: 1,
      levelOffset: level,
    },
  });

  const navigationItems: NavigationDataType[] = useMemo(
    () => navigationData?.navigation?.data?.main || [],
    [navigationData?.navigation?.data?.main],
  );

  const renderNavItems = useCallback(
    () =>
      navigationItems
        .filter((item: NavigationItemType) => !!item)
        .map((item: NavigationItemType) => {
          if (item.type === 'cmstextimagecomponent') {
            return <NavigationCard key={item.id} cardData={item} level={level} />;
          }

          return <NavigationItem key={item.id} itemData={item} level={level} />;
        }),
    [level, navigationItems],
  );

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return (
    <ul
      className={classnames(styles.list, styles[`level${level}`], {
        [styles.active]: isActive,
      })}
      tabIndex={-1}
    >
      <ShowCurrentMenu level={level} />
      {renderNavItems()}
    </ul>
  );
};

export default track(tagComponent('NavigationList'))(NavigationList);
