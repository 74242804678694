import React, { EventHandler, FC, MouseEventHandler, SyntheticEvent } from 'react';
import KeywordItem from 'modules/header/components/Suggest/components/KeywordItems/components/KeywordItem';

interface ItemProps {
  id: string;
  label: string;
}

interface KeywordItemsProps {
  /** Handle click callback */
  handleClick?: MouseEventHandler<HTMLAnchorElement>;
  /** Handle hover callback */
  handleHover?: EventHandler<SyntheticEvent<HTMLButtonElement>>;
  /** Items to display */
  items?: ItemProps[];
  /** Search term to highlight  */
  searchTerm?: string;
  /** Type ahead callback */
  typeAhead?: (searchTerm: string) => MouseEventHandler<HTMLButtonElement>;
}

const KeywordItems: FC<KeywordItemsProps> = ({
  items,
  handleClick,
  handleHover,
  searchTerm,
  typeAhead,
}) => {
  if (!items) {
    return null;
  }

  return items.map((item) => (
    <KeywordItem
      key={item.id}
      item={item}
      handleClick={handleClick}
      handleHover={handleHover}
      searchTerm={searchTerm}
      typeAhead={typeAhead}
    />
  ));
};

export default KeywordItems;
