import React, { type FC, type MouseEvent, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon/pseudoIcon';
import { arrowLeft } from '@xxxlgroup/hydra-icons';
import useMessage from 'components/Message/useMessage';
import LogoButton from 'components/LogoButton';
import type { NavigationDataType } from 'modules/header/components/SidebarMenu/SidebarMenu.types';
import { useSidebarMenuContext } from 'modules/header/components/SidebarMenu/SidebarMenu.context';

import styles from 'modules/header/components/SidebarMenu/components/BackButton/BackButton.scss';

const BackButton: FC = () => {
  const { activateNavigationItem, activeLevelStack } = useSidebarMenuContext();

  let translationMenuName = null;

  if (activeLevelStack.length > 1) {
    const previousItem = activeLevelStack[activeLevelStack.length - 2]
      .itemData as NavigationDataType;
    translationMenuName = { menuName: previousItem.name };
  }

  const [backToGenericTitle, backToMainMenuTitle] = useMessage(
    ['sidebarNavigation.backButton.generic.title', 'sidebarNavigation.backButton.mainMenu.title'],
    translationMenuName,
    true,
  );

  const newLevel = activeLevelStack.length - 1;
  const itemData = useMemo(
    () => activeLevelStack[newLevel]?.itemData || {},
    [activeLevelStack, newLevel],
  );

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      activateNavigationItem(event, newLevel, itemData);
    },
    [activateNavigationItem, itemData, newLevel],
  );

  if (activeLevelStack.length === 0) {
    return (
      <LogoButton
        dataPurpose="sidebarMenu.railLogo"
        linkClassName={styles.railLink}
        iconClassName={styles.railLogo}
      />
    );
  }

  const [iconStyle, iconClassName] = (arrowLeft && pseudoIcon(arrowLeft)) || [null, null];
  const usedBackMessage = activeLevelStack.length === 1 ? backToMainMenuTitle : backToGenericTitle;

  return (
    <button
      className={classnames(iconClassName, styles.backButton)}
      data-purpose="sidebarMenu.levelBack.button"
      onClick={handleClick}
      style={iconStyle}
    >
      <span className={styles.ellipsis}>{usedBackMessage}</span>
    </button>
  );
};

export default track(tagComponent('BackButton'))(BackButton);
