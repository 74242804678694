import React, { FC, FormEvent, useCallback, useMemo, useRef } from 'react';
import classnames from 'classnames';
import { FeedbackCard, Input } from '@xxxlgroup/hydra-ui-components';
import IconButton from '@xxxlgroup/hydra-ui-components/dist/common/components/IconButton/IconButton';
import { search } from '@xxxlgroup/hydra-icons';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import useMediaQuery from 'components/MediaQuery/useMediaQuery';
import useMessage from 'components/Message/useMessage';
import Suggest from 'modules/header/components/Suggest';
import ErrorBoundary from 'components/ErrorBoundary';
import useSearchBarController from 'modules/header/components/SearchBar/useSearchBarController';
import DeleteSearchTermButton from 'modules/header/components/SearchBar/components/DeleteSearchTermButton/DeleteSearchTermButton';
import ImageSearchButton from 'modules/header/components/ImageSearchButton/ImageSearchButton';
import SpeechToTextButton from 'modules/header/components/SpeechToTextButton/SpeechToTextButton';
import PromoteToolTip from 'modules/header/components/SearchBar/components/PromoteToolTip';
import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';
import { useLanguageSelector } from 'components/MetaNavigation/components/LanguageSelector/useLanguageSelector';

import styles from 'modules/header/components/SearchBar/SearchBar.scss';

export interface SearchBarProps {
  /** @ignore */
  className?: string;
  /** callback function for closing overlay in header */
  /* eslint-disable-next-line react/no-unused-prop-types */
  closeOverlay: () => void;
  /** is overlay open, used in external hook */
  /* eslint-disable-next-line react/no-unused-prop-types */
  isOverlayOpen: boolean;
  /** callback function for opening overlay in header, used in external hook */
  /* eslint-disable-next-line react/no-unused-prop-types */
  openOverlay?: () => void;
}

const SearchBar: FC<SearchBarProps> = (props) => {
  const { className, isOverlayOpen } = props;

  const inputWrapperRef = useRef<HTMLInputElement | null>(null);
  const formRef = useRef<HTMLFormElement | null>(null);

  const {
    callbacks: {
      closeTooltip,
      handleBlur,
      handleChange,
      handleClearValue,
      handleClick,
      handleCloseOverlay,
      handleDebounceTerm,
      handleFocus,
      handleHover,
      handleImageSearchHover,
      handleSearchButtonClick,
      handleSubmit,
      setIsSuggestEmpty,
      setIsVoiceSearch,
      setSearchTerm,
    },
    flags: {
      isImageSearchFeatureEnabled: isImageSearchEnabled,
      isImageSearchTooltipEnabled,
      isVoiceSearchEnabled,
    },
    states: { debounceTerm, isFocused, isTooltipFromHover, isTooltipFromFocus, searchTerm },
  } = useSearchBarController({
    formRef,
    inputWrapperRef,
    props,
  });

  const isTouch = useMediaQuery({ touchOnly: true });
  const isLanguageSelectorEnabled = useFeatureFlagsEnabled(
    'poseidon.multi.language.support.enabled',
  );

  const [inputLabel, buttonLabel, cancel, placeholder] = useMessage(
    [
      'wxs.header.searchBar.ariaLabel',
      'wxs.header.searchBar.button.ariaLabel',
      'wxs.header.searchBar.cancel',
      'wxs.header.searchBar.placeholder',
    ],
    null,
    true,
  );

  const { currentLanguage, defaultLanguage } = useLanguageSelector();
  const shouldDisableSearch = isLanguageSelectorEnabled && currentLanguage !== defaultLanguage;

  const isTooltipEnabled = isImageSearchEnabled && isImageSearchTooltipEnabled;
  const shouldShowToolTip = isTooltipEnabled && (isTooltipFromHover || isTooltipFromFocus);
  const i18n = useMemo(() => ({ cancel }), [cancel]);

  const PrefixButtons = useMemo(
    () => (
      <IconButton
        ariaLabel={buttonLabel}
        className={styles.prefixedSubmitButton}
        data-purpose="header.searchBar.button.submit"
        data-track-id="searchButton"
        glyph={search}
        onClick={handleSearchButtonClick}
        onFocus={handleHover}
        onMouseEnter={handleHover}
        type="submit"
      />
    ),
    [buttonLabel, handleHover, handleSearchButtonClick],
  );

  const SuffixButtons = useMemo(
    () => (
      <div className={styles.searchIconButtons}>
        <DeleteSearchTermButton
          styles={{ closeButton: styles.closeButton, closeButtonIcon: styles.closeButtonIcon }}
          handleClearValue={handleClearValue}
        />
        {isVoiceSearchEnabled && (
          <SpeechToTextButton
            setTranscribedText={setSearchTerm}
            handleSubmit={handleSubmit}
            setIsVoiceSearch={setIsVoiceSearch}
          />
        )}
        {isImageSearchEnabled && (
          <ImageSearchButton
            closeTooltip={closeTooltip}
            handleHover={handleImageSearchHover}
            withModal
          />
        )}
      </div>
    ),
    [
      closeTooltip,
      handleClearValue,
      handleImageSearchHover,
      handleSubmit,
      isImageSearchEnabled,
      setIsVoiceSearch,
      setSearchTerm,
      isVoiceSearchEnabled,
    ],
  );

  const createHandleFormSubmit = useCallback(() => {
    if (shouldDisableSearch) {
      return (event: FormEvent) => event.preventDefault();
    }
    return handleSubmit(searchTerm);
  }, [handleSubmit, searchTerm, shouldDisableSearch]);

  return (
    <form
      data-purpose="header.searchBar"
      className={classnames(className, styles.searchBar, {
        [styles.focused]: isFocused,
      })}
      onSubmit={createHandleFormSubmit()}
      ref={formRef}
      role="search"
    >
      <Input
        aria-label={inputLabel}
        autoComplete="off"
        className={classnames(styles.input, {
          [styles.searchEmpty]: searchTerm === '',
        })}
        data-purpose="header.searchBar.input"
        data-track-id="searchBox"
        enterKeyHint="search"
        forwardedRef={inputWrapperRef}
        hideCancelButton
        hideLabel
        i18n={i18n}
        inputMode="search"
        isTouch={isTouch}
        keepCancelButton={false}
        label={inputLabel}
        name="search"
        onBlur={handleBlur}
        onChange={handleChange}
        onClick={handleClick}
        onFocus={handleFocus}
        placeholder={placeholder}
        prefix={PrefixButtons}
        suffix={SuffixButtons}
        type="search"
        value={searchTerm}
      />
      {isOverlayOpen && (
        <>
          {shouldDisableSearch ? (
            <div className={styles.suggest}>
              <FeedbackCard className={styles.suggestDisabled} timerDuration="quick" fadeIn>
                {placeholder}
              </FeedbackCard>
            </div>
          ) : (
            <ErrorBoundary>
              <Suggest
                inputWrapperRef={inputWrapperRef}
                term={debounceTerm}
                updateDebounceTerm={handleDebounceTerm}
                onClickLink={handleCloseOverlay}
                setIsSuggestEmpty={setIsSuggestEmpty}
              />
            </ErrorBoundary>
          )}
        </>
      )}
      {shouldShowToolTip && (
        <PromoteToolTip
          closeTooltip={closeTooltip}
          handleHover={handleImageSearchHover}
          isTooltipFromFocus={isTooltipFromFocus}
        />
      )}
    </form>
  );
};

export default track(tagComponent('SearchBar'))(SearchBar);
