import React, { type FC, type MouseEvent, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import * as icons from '@xxxlgroup/hydra-icons';
import useLanguage from 'hooks/useLanguage';
import { useLocation } from 'react-router-dom';
import Link from 'components/WebshopLink';
import { useSidebarMenuContext } from 'modules/header/components/SidebarMenu/SidebarMenu.context';
import type { NavigationDataType } from 'modules/header/components/SidebarMenu/SidebarMenu.types';

import styles from 'modules/header/components/SidebarMenu/components/NavigationItemLink/NavigationItemLink.scss';
import { Icon } from '@xxxlgroup/hydra-ui-components';

interface NavigationItemLinkProps {
  /** tells the component to render the link inert */
  inert?: boolean;
  /** data of the current navigation item */
  itemData: NavigationDataType;
  /** how deeply the component is nested within the navigation */
  level: number;
}

const MOEMAX = 'moemax';
const XXXLUTZ = 'xxxlutz';
const SALE_LINK = '% Sale %';
const ANGEBOTE_LINK = ['Angebote', 'Kampanj'];

const NavigationItemLink: FC<NavigationItemLinkProps> = (props) => {
  const { inert, itemData, level } = props;
  const { hasSubItems, link, name, svg } = itemData;

  const target = link?.slice(0, 4) !== 'http' ? '_self' : '_blank';

  const { activateNavigationItem } = useSidebarMenuContext();

  const handleClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      activateNavigationItem(event, level + 1, { ...itemData, subItems: [] });
    },
    [activateNavigationItem, itemData, level],
  );

  const icon = useMemo(() => {
    if (!svg) {
      return null;
    }
    return Object.keys(icons).includes(svg) ? icons[svg as keyof typeof icons] : null;
  }, [svg]);

  const { pathname } = useLocation();
  const ariaCurrentPage = pathname === link ? 'page' : null;

  const language = useLanguage();
  const isNameToHighlight =
    (language.rail === MOEMAX && name === SALE_LINK) ||
    (language.rail === XXXLUTZ && ANGEBOTE_LINK.includes(name));

  return (
    <Link
      aria-current={ariaCurrentPage}
      aria-haspopup={String(hasSubItems)}
      className={classnames(styles.link, styles[`level${level}`], {
        [styles.highlight]: isNameToHighlight,
      })}
      data-purpose="sidebarMenu.navigationItem.link"
      href={link}
      inert={inert}
      onClick={handleClick}
      target={target}
    >
      {icon && <Icon aria-hidden className={styles.iconBefore} glyph={icon} />}
      {name}
      {hasSubItems && <Icon aria-hidden className={styles.iconAfter} glyph={icons.chevronUp} />}
    </Link>
  );
};

export default track(tagComponent('NavigationItemLink'))(NavigationItemLink);
