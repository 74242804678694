import React, {
  type FC,
  type MouseEvent,
  type MouseEventHandler,
  useCallback,
  useMemo,
  memo,
} from 'react';
import classnames from 'classnames';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import { arrowRotation, loyaltyCard, parcel, user } from '@xxxlgroup/hydra-icons';
import { useAuth } from 'modules/customer-authentication/AuthContext';
import { useGreeting } from 'modules/customer-account/hooks/useGreeting';
import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';
import useRoutes from 'hooks/useRoutes';
import useMessage from 'components/Message/useMessage';
import Link from 'components/WebshopLink';

import styles from 'modules/header/components/SidebarMenu/components/ServiceLinks/ServiceLinks.scss';
import { Icon } from '@xxxlgroup/hydra-ui-components';

interface ServiceLinksProps {
  onClick: MouseEventHandler<HTMLAnchorElement>;
}

const ServiceLinks: FC<ServiceLinksProps> = ({ onClick }) => {
  const tracking = useTracking({}, 'ServiceLinks');

  const { customerAccount, customerCardInfo, orderInformationPage } = useRoutes();

  const { isAnonymous, isPoseidonLoginEnabled } = useAuth();
  const isAccountEnabled =
    useFeatureFlagsEnabled('poseidon.account.enabled') && isPoseidonLoginEnabled;

  const { email, loading, hasError }: { email?: string; loading?: boolean; hasError?: boolean } =
    useGreeting();

  const renderGreeting = useMemo(() => {
    if (loading || hasError || !email) {
      return null;
    }
    return <div className={styles.greeting}>{email}</div>;
  }, [email, hasError, loading]);

  const [myAccountMsg, purchasesMsg, orderStatusButtonMsg, customerCardMsg] = useMessage([
    'sidebarNavigation.serviceLinks.myAccount.title',
    'sidebarNavigation.serviceLinks.purchases.title',
    'sidebarNavigation.serviceLinks.orderStatus.title',
    'sidebarNavigation.serviceLinks.customerCard.title',
  ]);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      tracking(event);
      onClick(event);
    },
    [onClick, tracking],
  );

  const customerCardLink = isAnonymous
    ? customerCardInfo.name
    : `/${customerAccount.name}/${customerAccount.customerCard.name}`;

  return (
    <>
      {isAccountEnabled && (
        <>
          <Link
            data-purpose="sidebarMenu.serviceLinks.myAccount"
            href={`/${customerAccount.name}`}
            key={customerAccount.name}
            className={styles.link}
            onClick={handleClick}
          >
            <Icon aria-hidden className={styles.icon} glyph={user} />
            {myAccountMsg}
            {renderGreeting}
          </Link>
          <Link
            data-purpose="sidebarMenu.serviceLinks.purchases"
            href={`/${customerAccount.name}/${customerAccount.purchases.name}`}
            key={customerAccount.purchases.name}
            className={styles.link}
            onClick={handleClick}
          >
            <Icon aria-hidden className={styles.icon} glyph={parcel} />
            {purchasesMsg}
          </Link>
        </>
      )}
      <Link
        data-purpose="sidebarMenu.serviceLinks.orderStatus"
        href={`/${orderInformationPage.name}`}
        key={orderInformationPage.name}
        className={styles.link}
        onClick={handleClick}
      >
        <Icon
          aria-hidden
          className={classnames(styles.icon, styles.flipped)}
          glyph={arrowRotation}
        />
        {orderStatusButtonMsg}
      </Link>
      {isAccountEnabled && (
        <Link
          data-purpose="sidebarMenu.serviceLinks.customerCard"
          href={customerCardLink}
          key={customerAccount.customerCard.name}
          className={styles.link}
          onClick={handleClick}
        >
          <Icon aria-hidden className={styles.icon} glyph={loyaltyCard} />
          {customerCardMsg}
        </Link>
      )}
    </>
  );
};

export default track(tagComponent('ServiceLinks'))(memo(ServiceLinks));
