import React, { type FC, type MouseEvent, type MouseEventHandler, useCallback, memo } from 'react';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import { Button, Link as HydraLink } from '@xxxlgroup/hydra-ui-components';
import Link from 'components/WebshopLink';
import { useAuth } from 'modules/customer-authentication/AuthContext';
import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';
import useMessage from 'components/Message/useMessage';
import useRoutes from 'hooks/useRoutes';

import styles from 'modules/header/components/SidebarMenu/components/LoginLogoutButton/LoginLogoutButton.scss';

interface LoginLogoutButtonProps {
  onClick: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
}

const LoginLogoutButton: FC<LoginLogoutButtonProps> = ({ onClick }) => {
  const [loginMessage, logoutMessage] = useMessage(
    ['wxs.user.login.button', 'user.logout.button'],
    null,
    true,
  );
  const { isAnonymous, isPoseidonLoginEnabled, logout } = useAuth();
  const isAccountEnabled = useFeatureFlagsEnabled('poseidon.account.enabled');
  const routes = useRoutes();

  const showLogin = isPoseidonLoginEnabled && isAccountEnabled;

  const tracking = useTracking({}, 'LoginLogoutButton');

  const handleLogout = useCallback(
    async (event: MouseEvent<Element>) => {
      tracking(event, {
        context: [],
        props: { action: 'Logout' },
        type: 'Button',
      });
      logout();
    },
    [logout, tracking],
  );

  const handleClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
      tracking(event, {
        context: [],
        props: { action: 'Login' },
        type: 'Link',
      });
      onClick(event);
    },
    [onClick, tracking],
  );

  if (!showLogin) {
    return null;
  }

  if (isAnonymous) {
    return (
      <Link
        data-purpose="sidebarMenu.login.link"
        as={<Button layout="block" variant="primary" />}
        href={`/${routes.customerAuthentication.signIn.name}`}
        onClick={handleClick}
      >
        {loginMessage}
      </Link>
    );
  }

  return (
    <HydraLink
      layout="block"
      typography="regular"
      theme="coal"
      data-purpose="sidebarMenu.logout.button"
      onClick={handleLogout}
      className={styles.logout}
    >
      {logoutMessage}
    </HydraLink>
  );
};

export default track(tagComponent('LoginLogoutButton'))(memo(LoginLogoutButton));
