import React, { useCallback, useEffect, useState, useMemo, useContext } from 'react';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import classnames from 'classnames';
import { useLocation, useRouteMatch } from 'react-router';
import * as icons from '@xxxlgroup/hydra-icons';
import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useRoutes from 'hooks/useRoutes';
import useIsModeHeadless from 'hooks/useIsModeHeadless';
import useIsMobileApp from 'hooks/useIsMobileApp';
import useIsMountedOnCSR from 'hooks/useIsMountedOnCsr';
import useMessage from 'components/Message/useMessage';
import { useAuth } from 'modules/customer-authentication/AuthContext';
import { useCartsQuery } from 'utils/cart';
import MetaNavigation from 'components/MetaNavigation';
import LogoButton from 'components/LogoButton';
import ErrorBoundary from 'components/ErrorBoundary';
import Hamburger from 'modules/header/components/Hamburger';
import SkipNavigation from 'modules/header/components/SkipNavigation';
import SearchBar from 'modules/header/components/SearchBar';
import FavouritesFlyout from 'modules/favourites/components/FavouritesFlyout/FavouritesFlyout';
import useMergeCart from 'pages/Cart/hooks/useMergeCart';
import ActionIcon from 'modules/header/components/ActionIcon';
import HeaderLoginActionIconBig from 'modules/customer-authentication/components/HeaderLoginActionIcon';
import HeaderLogoutActionIconBig from 'modules/customer-authentication/components/HeaderLogoutActionIcon';
import ReservationsMini from 'components/Entries/EntryLists/ReservationsMini';
import CartMini from 'components/Entries/EntryLists/CartMini';
import MainNavigation from 'modules/header/components/MainNavigation';
import { getFavouritesProducts } from 'modules/favourites/utils/favourites';
import { getTotalProductsAmount } from 'utils/cart/misc';
import { FavouritesContext } from 'modules/favourites/pages/Favourites/Favourites.state';
import { CartPageContextProvider } from 'pages/Cart/contexts/CartContext';

import styles from 'modules/header/Header.scss';

const Header = () => {
  const { cart: cartIcon, like, reserve } = icons;

  const location = useLocation();
  const { cart: cartLink, checkout, orders, reservationPage, favouritesPage } = useRoutes();
  const matchConfirmationPage = useRouteMatch({ path: [orders.confirmation] });

  // Mini-cart, reservations and favourites do not make sense to render on the server because we do not know localstorage
  // state of the browser. This hook ensures that they get re-rendered on CSR hydration.
  const isMountedOnCSR = useIsMountedOnCSR();
  const { isAnonymous } = useAuth();
  const [isFavouritesEnabled, isMetaNavigationEnabled] = useFeatureFlags([
    'poseidon.favourites',
    'poseidon.nav.metaNavigation.enabled',
  ]);
  const isGetCartEnabled =
    useFeatureFlagsEnabled(['poseidon.checkout', 'checkout']) && !matchConfirmationPage;
  const isGetReservationsEnabled = useFeatureFlagsEnabled(['poseidon.reservation', 'reservation']);
  const newCartMergeEnable = useFeatureFlagsEnabled('login.cartMerge.disabled');
  const isModeHeadless = useIsModeHeadless();
  const isMobileApp = useIsMobileApp();

  const pagesWithReducedHeader = useRouteMatch({
    path: [reservationPage.root, checkout.root],
  });
  const showReducedHeader = useMemo(() => {
    if ([`/${reservationPage.name}`].includes(location.pathname)) {
      return false;
    }

    return pagesWithReducedHeader;
  }, [location.pathname, pagesWithReducedHeader, reservationPage.name]);

  const [isOverlayOpen, setOverlayOpen] = useState(false);

  const [
    favouritesAriaLabel,
    reservationAriaLabel,
    cartAriaLabel,
    bookmarksLabel,
    favouritesLabel,
    reservationLabel,
    cartLabel,
  ] = useMessage(
    [
      'favourites.link.ariaLabel',
      'reservation.link.ariaLabel',
      'cart.link.ariaLabel',
      'header.actionIcons.bookmarks.label',
      'header.actionIcons.favourites.label',
      'header.actionIcons.reservation.label',
      'header.actionIcons.cart.label',
    ],
    null,
    true,
  );

  const { favouritesState } = useContext(FavouritesContext);
  const { favourites } = favouritesState;
  const products = getFavouritesProducts(favourites);

  const {
    loading: cartsLoading,
    data: orderCarts = {},
    refetch: refetchCart,
  } = useCartsQuery({
    skip: !isGetCartEnabled,
    fetchPolicy: 'network-only',
  });

  const {
    loading: reservationLoading,
    data: reservationCarts = {},
    refetch: refetchReservation,
  } = useCartsQuery({
    skip: !isGetReservationsEnabled,
    variables: { type: 'RESERVATION' },
  });

  const favouritesCount = useMemo(() => products?.length || 0, [products]);

  const reservationProductsCount = useMemo(
    () => getTotalProductsAmount(reservationCarts?.getCarts?.carts?.[0]?.entries),
    [reservationCarts?.getCarts?.carts],
  );

  const orderedProductsCount = useMemo(
    () => getTotalProductsAmount(orderCarts?.getCarts?.carts?.[0]?.entries),
    [orderCarts?.getCarts?.carts],
  );

  const openOverlay = useCallback(() => {
    setOverlayOpen(true);
  }, []);

  const closeOverlay = useCallback(() => {
    setOverlayOpen(false);
  }, []);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => event.key === 'Enter' && closeOverlay(),
    [closeOverlay],
  );

  useEffect(() => {
    refetchCart();
  }, [isAnonymous, refetchCart]);

  useEffect(() => {
    refetchReservation();
  }, [isAnonymous, refetchReservation]);

  const mergeCart = useMergeCart({
    refetchCart,
    isAnonymous,
    refetchReservation,
    reservationId: reservationCarts?.getCarts?.carts?.[0]?.code,
    cartId: orderCarts?.getCarts?.carts?.[0]?.code,
  });

  useEffect(() => {
    if (newCartMergeEnable) {
      mergeCart();
    }
  }, [mergeCart, newCartMergeEnable]);

  if (isModeHeadless || isMobileApp) {
    return null;
  }

  if (showReducedHeader) {
    return (
      <header data-purpose="header" id="navigation-start" className={styles.header}>
        <div className={classnames(styles.headerContainer, styles.showReducedHeader)}>
          <LogoButton
            linkClassName={classnames(styles.logo, styles.showReducedHeader)}
            iconClassName={styles.logoIcon}
            dataPurpose="header.railLogo"
          />
          <SkipNavigation />
        </div>
      </header>
    );
  }

  return (
    <header
      data-purpose="header"
      id="navigation-start"
      className={classnames(styles.header, {
        [styles.withOverlay]: isOverlayOpen,
      })}
    >
      {isMetaNavigationEnabled && <MetaNavigation />}
      <ErrorBoundary>
        <div className={styles.headerContainer}>
          <Hamburger />
          <LogoButton
            linkClassName={styles.logo}
            iconClassName={styles.logoIcon}
            dataPurpose="header.railLogo"
          />
          <SkipNavigation />
          <SearchBar
            closeOverlay={closeOverlay}
            openOverlay={openOverlay}
            isOverlayOpen={isOverlayOpen}
          />
          <div className={styles.actionIcons}>
            {isMountedOnCSR && (
              <>
                <HeaderLogoutActionIconBig />
                <HeaderLoginActionIconBig />
              </>
            )}
            {isFavouritesEnabled && (
              <ActionIcon
                glyph={like}
                dataPurpose="wxs.header.actions.favourites"
                ariaLabel={favouritesAriaLabel}
                label={isAnonymous ? bookmarksLabel : favouritesLabel}
                badge={favouritesCount}
                link={favouritesPage.root}
              >
                {isMountedOnCSR && <FavouritesFlyout />}
              </ActionIcon>
            )}
            {isGetReservationsEnabled && !reservationLoading && (
              <ActionIcon
                className={classnames({
                  [styles.hideIcon]: !reservationProductsCount,
                })}
                glyph={reserve}
                badge={reservationProductsCount}
                dataPurpose="wxs.header.actions.reservation"
                ariaLabel={reservationAriaLabel}
                label={reservationLabel}
                link={`/${reservationPage.name}`}
              >
                {isMountedOnCSR && <ReservationsMini reservationCarts={reservationCarts} />}
              </ActionIcon>
            )}
            {isGetCartEnabled && (
              <ActionIcon
                glyph={cartIcon}
                badge={orderedProductsCount}
                dataPurpose="wxs.header.actions.cart"
                ariaLabel={cartAriaLabel}
                label={cartLabel}
                link={cartLink}
              >
                {!cartsLoading && isMountedOnCSR && (
                  <CartPageContextProvider>
                    <CartMini orderCarts={orderCarts} />
                  </CartPageContextProvider>
                )}
              </ActionIcon>
            )}
          </div>
          <div
            className={styles.overlay}
            data-purpose="header.overlay"
            onClick={closeOverlay}
            onKeyDown={handleKeyDown}
            role="presentation"
          />
          <MainNavigation className={styles.mainNavigation} />
        </div>
      </ErrorBoundary>
    </header>
  );
};

export default track(tagComponent('Header'))(Header);
