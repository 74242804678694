import React, { KeyboardEvent, useEffect, useRef } from 'react';
import useMessage from 'components/Message/useMessage';
import { useLocation } from 'react-router-dom';

import styles from 'modules/header/components/SkipNavigation/SkipNavigation.scss';

const SkipNavigation = () => {
  const skipMessage = useMessage('wxs.header.skipNavigation');
  const { hash } = useLocation();
  const keyDownRef = useRef(false);

  useEffect(() => {
    const isHashEmptyAndNotMain = hash !== undefined && hash !== '' && hash !== '#main';
    if (isHashEmptyAndNotMain) {
      keyDownRef.current = true;
    }
  }, [hash]);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && keyDownRef.current) {
      event.preventDefault();
      const heading = document.querySelector('h1');
      if (heading) {
        heading.setAttribute('tabindex', '-1');
        heading.style.outline = 'none';
        heading.focus({
          preventScroll: true,
        });
      }
    }
  };

  return (
    <a href="#main" onKeyDown={handleKeyDown} className={styles.SkipNavigation}>
      {skipMessage}
    </a>
  );
};

export default SkipNavigation;
