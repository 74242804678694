import React, { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { local } from '@xxxlgroup/hydra-utils/storage';
import { useCategoryContext } from 'pages/CategoryView/provider/Category.provider';
import { debouncing } from 'utils/function';
import { useTracking } from 'utils/tracking/hooks';
import { getSearchUrl } from 'utils/url';
import useFeatureFlags from 'hooks/useFeatureFlags';
import {
  searchBarVar,
  useSearchBar,
} from 'modules/header/components/SearchBar/SearchBar.variables';
import { SEARCHTERM_QUERY } from 'modules/header/components/SearchBar/SearchBar.query';
import { IMAGE_SEARCH_TOOLTIP_DISPLAYED } from 'modules/header/components/ImageSearchButton/ImageSearchButton';
import type { SearchBarProps } from 'modules/header/components/SearchBar/SearchBar';
import useMediaQuery from 'components/MediaQuery/useMediaQuery';
import { Breakpoint } from 'components/MediaQuery/MediaQuery.types';
import { SEARCH_BAR_IMAGE_INTERACTION_PURPOSE } from 'pages/ImageSearch/ImageSearch.state';

export const IMAGE_SEARCH_ENABLED = 'poseidon.searchBar.imageSearch.enabled';
export const VOICE_SEARCH_ENABLED = 'poseidon.searchBar.voiceSearch.enabled';
export const IMAGE_SEARCH_TOOLTIP_ENABLED = 'poseidon.searchBar.imageSearch.toolTip.enabled';

export const SEARCHTERM_LENGTH = 3;
const PROMOTE_TOOLTIP_TRACKING_TYPE = 'PromoteToolTip';
const SEARCH_BAR_INTERACTION_PURPOSE = 'search.bar.interactions';

const debounceCall = debouncing((func: () => void) => {
  func();
}, 200);

interface UseSearchBarControllerProps {
  formRef: React.RefObject<HTMLFormElement>;
  inputWrapperRef: React.RefObject<HTMLInputElement>;
  props: SearchBarProps;
}

const useSearchBarController = ({
  formRef,
  inputWrapperRef,
  props,
}: UseSearchBarControllerProps) => {
  const { closeOverlay, openOverlay } = props;

  const isMobileLayout = useMediaQuery({ smallerThan: Breakpoint.lg });
  const tracking = useTracking(props, 'Searchbar');
  const { data: searchTermData } = useQuery(SEARCHTERM_QUERY);
  const searchBarTerm = searchTermData?.searchBar?.term;

  const { searchTracking } = useCategoryContext();
  const [isImageSearchFeatureEnabled, isImageSearchTooltipEnabled, isVoiceSearchEnabled] =
    useFeatureFlags([IMAGE_SEARCH_ENABLED, IMAGE_SEARCH_TOOLTIP_ENABLED, VOICE_SEARCH_ENABLED]);
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { resetSearchTerm, updateSearchTerm, updateSuggestVisibility } = useSearchBar();
  const [debounceTerm, setDebounceTerm] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSuggestEmpty, setIsSuggestEmpty] = useState(false);
  const [isTooltipFromHover, setIsTooltipFromHover] = useState(false);
  const [isTooltipFromFocus, setIsTooltipFromFocus] = useState(false);
  const [isVoiceSearch, setIsVoiceSearch] = useState(false);

  const keyupListenerAdded = useRef(false);

  const closeTooltip = useCallback(() => {
    setIsTooltipFromHover(false);
    setIsTooltipFromFocus(false);
  }, []);

  useEffect(() => {
    closeTooltip();
  }, [closeTooltip, pathname, search]);

  const searchInputBlur = useCallback(() => {
    if (inputWrapperRef.current?.firstChild) {
      (inputWrapperRef.current.firstChild as HTMLInputElement).blur();
    }
  }, [inputWrapperRef]);

  useEffect(
    () => document.body.removeEventListener('touchmove', searchInputBlur),
    [searchInputBlur],
  );

  const handleHover = useCallback(
    (event: React.FocusEvent | React.MouseEvent) => {
      tracking(event);
    },
    [tracking],
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent) => {
      setIsFocused(false);
      handleHover(event);

      const isOutsideSearchBar =
        !isSuggestEmpty && !formRef.current?.contains(event.target as Node);

      if (isOutsideSearchBar || isSuggestEmpty) {
        closeOverlay();
      }

      if (isMobileLayout) {
        document.body.removeEventListener('touchmove', searchInputBlur);
      }
    },
    [closeOverlay, isSuggestEmpty, formRef, handleHover, isMobileLayout, searchInputBlur],
  );

  const handleCloseOverlay = useCallback(() => {
    debounceCall(() => {
      !isMobileLayout && closeOverlay();
    });
  }, [closeOverlay, isMobileLayout]);

  const handleSubmit = useCallback(
    (term: string) => (event: FormEvent | Event) => {
      event.preventDefault();
      tracking(event);
      searchInputBlur();
      searchTracking.current.method = 'manual';
      document.getElementById('main')?.focus({
        preventScroll: true,
      });
      history.push(getSearchUrl(encodeURIComponent(term)));

      debounceCall(() => {
        updateSuggestVisibility(false);
        handleCloseOverlay();
      });
    },
    [
      handleCloseOverlay,
      history,
      searchInputBlur,
      searchTracking,
      tracking,
      updateSuggestVisibility,
    ],
  );

  const handleSuggest = useCallback(
    (value: string) => {
      updateSuggestVisibility(value?.length >= SEARCHTERM_LENGTH);
    },
    [updateSuggestVisibility],
  );

  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      const { key, target } = event;
      if (key !== 'Tab') {
        return;
      }

      const isOutsideSearchBar = !isSuggestEmpty && !formRef.current?.contains(target as Node);
      const isOutsideInput = isSuggestEmpty && (target as HTMLElement).tagName !== 'INPUT';

      if (isOutsideSearchBar || isOutsideInput) {
        closeOverlay();
      }
    },
    [closeOverlay, formRef, isSuggestEmpty],
  );

  useEffect(() => {
    if (!keyupListenerAdded.current) {
      document.body.addEventListener('keyup', handleKeyUp);
      keyupListenerAdded.current = true;
    }

    return () => {
      document.body.removeEventListener('keyup', handleKeyUp);
      keyupListenerAdded.current = false;
    };
  }, [handleKeyUp, inputWrapperRef]);

  const handleFocus = useCallback(
    (event: React.FocusEvent | null) => {
      // always set the focus to true and open the suggest-component when focused
      if (!isFocused) {
        setIsFocused(true);

        if (isImageSearchFeatureEnabled && !local.getItem(IMAGE_SEARCH_TOOLTIP_DISPLAYED)) {
          local.setItem(IMAGE_SEARCH_TOOLTIP_DISPLAYED, true);
          setIsTooltipFromFocus(true);
          tracking(event, { type: PROMOTE_TOOLTIP_TRACKING_TYPE });
        }

        openOverlay?.();

        if (!isVoiceSearch) {
          handleSuggest(searchTerm);
        }
      }

      if (isMobileLayout) {
        document.body.addEventListener('touchmove', searchInputBlur);
      }
    },
    [
      handleSuggest,
      isFocused,
      isImageSearchFeatureEnabled,
      isMobileLayout,
      isVoiceSearch,
      openOverlay,
      searchInputBlur,
      searchTerm,
      tracking,
    ],
  );

  const handleChange = useCallback(
    (event: Event) => {
      const { value } = event.target as HTMLInputElement;
      setSearchTerm(value);

      if (value === '') {
        resetSearchTerm();
      }

      // fixes type ahead autofill bug when you highlight the text in the searchbar and immediately type another character
      if (value.trim().length === 1 && searchBarVar().term) {
        updateSearchTerm(value);
      }

      if (value.length >= SEARCHTERM_LENGTH) {
        tracking(event, {
          event: { type: 'click', purpose: SEARCH_BAR_INTERACTION_PURPOSE },
          props: { action: 'typed text' },
        });
      }
    },
    [resetSearchTerm, updateSearchTerm, tracking],
  );

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      // the overlay and suggest should remain in focus and open when clicking the input field
      handleFocus(null);

      tracking(event, {
        event: { type: 'click', purpose: SEARCH_BAR_INTERACTION_PURPOSE },
        props: { action: 'click in bar' },
      });

      isMobileLayout && window.scrollTo(0, (formRef.current?.offsetTop ?? 0) + 1);
    },
    [formRef, handleFocus, isMobileLayout, tracking],
  );

  const handleDebounceTerm = useCallback(
    (notion: string) => {
      setDebounceTerm(encodeURIComponent(notion));
      setSearchTerm(notion);
    },
    [setDebounceTerm, setSearchTerm],
  );

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const { target } = event;
      const isInput = (target as HTMLElement).tagName === 'INPUT';

      // close when target is not input and not within the PromoteTooltip
      if (!isInput && !(isTooltipFromHover || isTooltipFromFocus)) {
        closeOverlay();
      }
    },
    [closeOverlay, isTooltipFromFocus, isTooltipFromHover],
  );

  const handleClearValue = useCallback(() => {
    setSearchTerm('');
    resetSearchTerm();
    setTimeout(() => {
      const element = formRef.current?.elements?.namedItem('search');
      if (element) {
        (element as HTMLInputElement).focus();
      }
    }, 0);
  }, [resetSearchTerm, setSearchTerm, formRef]);

  const handleImageSearchHover = useCallback(
    (event: React.MouseEvent) => {
      setIsTooltipFromHover(!isMobileLayout && event.type === 'mouseenter');

      tracking(event, {
        type: 'imageSearch',
        event: { type: 'hover', purpose: SEARCH_BAR_IMAGE_INTERACTION_PURPOSE },
        props: { action: 'Hover-Tooltip' },
      });
    },
    [isMobileLayout, tracking],
  );

  const handleSearchButtonClick = useCallback(
    (event: React.MouseEvent) => {
      closeOverlay();

      tracking(event, {
        event: { type: 'click', purpose: SEARCH_BAR_INTERACTION_PURPOSE },
        props: { action: 'click on the magnifying glass' },
      });
    },
    [closeOverlay, tracking],
  );

  useEffect(() => {
    setSearchTerm(searchBarTerm || '');
  }, [searchBarTerm]);

  useEffect(() => {
    document.body.addEventListener('click', handleClickOutside, false);

    return () => {
      document.body.removeEventListener('click', handleClickOutside, false);
    };
  }, [handleClickOutside]);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;

      return;
    }

    debounceCall(() => {
      handleDebounceTerm(searchTerm);

      if (!isVoiceSearch) {
        openOverlay?.();

        handleSuggest(searchTerm);
      }
    });
  }, [handleDebounceTerm, handleSuggest, isVoiceSearch, openOverlay, searchTerm]);

  useEffect(() => {
    // to focus input search in mobile after click search icon
    if (isFocused) {
      (inputWrapperRef.current?.firstChild as HTMLInputElement)?.focus();
    }
  }, [inputWrapperRef, isFocused]);

  return {
    callbacks: {
      closeTooltip,
      handleBlur,
      handleChange,
      handleClearValue,
      handleClick,
      handleCloseOverlay,
      handleDebounceTerm,
      handleFocus,
      handleHover,
      handleImageSearchHover,
      handleSubmit,
      handleSearchButtonClick,
      setIsSuggestEmpty,
      setIsVoiceSearch,
      setSearchTerm,
    },
    flags: {
      isImageSearchFeatureEnabled,
      isImageSearchTooltipEnabled,
      isVoiceSearchEnabled,
    },
    states: {
      debounceTerm,
      isFocused,
      isTooltipFromHover,
      isTooltipFromFocus,
      searchTerm,
    },
  };
};

export default useSearchBarController;
